import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const qrGifImg = require("assets/img/animation/qr-code.gif");
export const eventGifImg = require("assets/img/animation/confetti.gif");
export const launchGifImg = require("assets/img/animation/fireworks.gif");
export const passGifImg = require("assets/img/animation/passport.gif");

export const defaultFormImg = require("assets/img/forms/image.svg");
export const defaultFormBanner = require("assets/img/forms/banner.svg");
export const defaultProfileImg = require("assets/img/default/profile.svg");
export const defaultBoxGrayImg = require("assets/img/default/rpbox.svg");
export const bgGradient = require("assets/img/banners/gradient-banner.svg");
export const verifiedImg = require("assets/img/icons/verified-blue.svg");
export const gelBannerImg = require("assets/img/banners/gel-banner.svg");
export const nsfwImg = require("assets/img/icons/nsfw.svg");

export const logoBlackIcon = require("assets/img/logos/logo-icon.svg");
export const logoWhiteImg = require("assets/img/logos/logo-white.svg");
export const passChipImg = require("assets/img/logos/realpass-chip-icon.svg");

export const bgAbstractLine01 = require("assets/img/bg/abstract-line-01.svg");
export const bgAbstractLine02 = require("assets/img/bg/abstract-line-02.svg");
export const bgAbstractLine03 = require("assets/img/bg/abstract-line-03.svg");
export const bgAbstractLine04 = require("assets/img/bg/abstract-line-04.svg");
export const bgAbstractLine05 = require("assets/img/bg/abstract-line-05.svg");

export const mmImg = require("assets/img/wallets/mm.svg");
export const wcImg = require("assets/img/wallets/wc.svg");
export const pwImg = require("assets/img/wallets/pw.svg");
export const ethImg = require("assets/img/chains/eth-gray.svg");
export const ethWhiteImg = require("assets/img/chains/eth-white.svg");
export const ethLogoColor = require("assets/img/chains/eth-logo-color.svg");
export const blastImg = require("assets/img/chains/blast-gray.svg");
export const blastWhiteImg = require("assets/img/chains/blast-white.svg");
export const blastLogoColor = require("assets/img/chains/blast-logo-color.svg");
export const polygonImg = require("assets/img/chains/polygon-gray.svg");
export const polygonWhiteImg = require("assets/img/chains/polygon-white.svg");
export const polygonLogoColor = require("assets/img/chains/polygon-logo-color.svg");
export const bscLogoColor = require("assets/img/chains/bnb-logo-color.svg");
export const ftmLogoColor = require("assets/img/chains/ftm-logo-color.svg");
export const avaxLogoColor = require("assets/img/chains/avax-logo-color.svg");
export const cronosLogoColor = require("assets/img/chains/cronos-logo-color.svg");
export const solLogoColor = require("assets/img/chains/sol-logo-color.svg");
export const usdtLogoColor = require("assets/img/chains/usdt-logo-color.svg");
export const btcLogoColor = require("assets/img/chains/btc-logo-color.svg");
export const apeLogoColor = require("assets/img/chains/ape-logo-color.svg");

export const catTokenImg = require("assets/img/icons/category-token.svg");
export const catArtImg = require("assets/img/icons/category-art.svg");
export const catMusicImg = require("assets/img/icons/category-music.svg");
export const catGameImg = require("assets/img/icons/category-game.svg");
export const catPartyImg = require("assets/img/icons/category-party.svg");
export const catSportsImg = require("assets/img/icons/category-sports.svg");
export const catDaoImg = require("assets/img/icons/category-dao.svg");
export const catMeetingImg = require("assets/img/icons/category-meeting.svg");
export const catStudyImg = require("assets/img/icons/category-study.svg");
export const catLeisureImg = require("assets/img/icons/category-leisure.svg");
export const catFundingImg = require("assets/img/icons/category-funding.svg");
export const catMiscImg = require("assets/img/icons/category-misc.svg");

export const qrScanImg = require("assets/img/icons/qr-scan.svg");
export const qrScanPlainImg = require("assets/img/icons/qr-scan-plain.svg");
export const qrScanLaserImg = require("assets/img/icons/qr-scan-laser.svg");
export const qrScanAccessImg = require("assets/img/icons/qr-scan-access.svg");
export const qrScanSuccessImg = require("assets/img/icons/qr-scan-success.svg");
export const qrScanFailedImg = require("assets/img/icons/qr-scan-failed.svg");

export const calImg = require("assets/img/icons/calendar.svg");
export const gCalImg = require("assets/img/icons/google_cal.svg");
export const outlookImg = require("assets/img/icons/outlook.svg");

export const ClientIpLimit = 20;
export const EventRegisterLimit = 10;
export const NoLimit = -1;
export const DefaultPageSize = 20;
export const DefaultEventSessionSize = 10;
export const DefaultSocialLinkSize = 5;
export const DefaultPassSize = 5;

export const DefaultLocation = { name: "" };

export const ChainLogos = {
  eth: ethLogoColor,
  blast: blastLogoColor,
  polygon: polygonLogoColor,
  bsc: bscLogoColor,
  ftm: ftmLogoColor,
  avax: avaxLogoColor,
  cronos: cronosLogoColor,
  sol: solLogoColor,
};

export const ChainOptions = {
  eth: { image: ethLogoColor, name: "Ethereum Network" },
  blast: { image: blastLogoColor, name: "Blast Network" },
  rinkeby: { image: ethLogoColor, name: "Rinkeby Network" },
  matic: { image: polygonLogoColor, name: "Polygon Network" },
  mumbai: { image: polygonLogoColor, name: "Mumbai Network" },
  bsc: { image: bscLogoColor, name: "BSC Network" },
  ftm: { image: ftmLogoColor, name: "Fantom Network" },
  avax: { image: avaxLogoColor, name: "Avalanche Network" },
  cronos: { image: cronosLogoColor, name: "Cronos Network" },
  sol: { image: solLogoColor, name: "Solana Network" },
};

export const ChainSelectorOptions = [
  // {
  //   value: "eth",
  //   label: (
  //     <div>
  //       <img src={ethImg} className="icon-eth mr-2" />
  //       <span>Ethereum</span>
  //     </div>
  //   ),
  // },
  {
    value: "blast",
    label: (
      <div>
        <img src={blastImg} className="icon-blast mr-2" />
        <span>Blast</span>
      </div>
    ),
  },
  // {
  //   value: "matic",
  //   label: (
  //     <div>
  //       <img src={polygonImg} className="icon-polygon mr-2" />
  //       <span>Polygon</span>
  //     </div>
  //   ),
  // },
];

export const CurrencyImageOptions = {
  "usdt": usdtLogoColor,
  "wbtc": btcLogoColor,
  "eth": ethLogoColor,
  "ape": apeLogoColor,
};

export const CurrencySelectorOptions = [
  // {
  //   value: "wbtc",
  //   label: (
  //     <div>
  //       <img src={btcLogoColor} className="icon-coin-sm mr-2" />
  //       <span>WBTC</span>
  //     </div>
  //   ),
  // },
  // {
  //   value: "eth",
  //   label: (
  //     <div>
  //       <img src={ethLogoColor} className="icon-coin-sm mr-2" />
  //       <span>ETH</span>
  //     </div>
  //   ),
  // },
  {
    value: "usdt",
    label: (
      <div>
        <img src={usdtLogoColor} className="icon-coin-sm mr-2" />
        <span>USDT</span>
      </div>
    ),
  },
  // {
  //   value: "ape",
  //   label: (
  //     <div>
  //       <img src={apeLogoColor} className="icon-coin-sm mr-2" />
  //       <span>APE</span>
  //     </div>
  //   ),
  // },
];

export const EventIcons = {
  token: <FontAwesomeIcon icon="fa-coins" />,
  art: <FontAwesomeIcon icon="fa-palette" />,
  music: <FontAwesomeIcon icon="fa-music" />,
  gaming: <FontAwesomeIcon icon="fa-gamepad" />,
  party: <FontAwesomeIcon icon="fa-champagne-glasses" />,
  sports: <FontAwesomeIcon icon="fa-person-swimming" />,
  dao: <FontAwesomeIcon icon="fa-lightbulb" />,
  meeting: <FontAwesomeIcon icon="fa-podcast" />,
  study: <FontAwesomeIcon icon="fa-graduation-cap" />,
  leisure: <FontAwesomeIcon icon="fa-umbrella-beach" />,
  fundraising: <FontAwesomeIcon icon="fa-hand-holding-heart" />,
  misc: <FontAwesomeIcon icon="fa-puzzle-piece" />,
};

export const EventCategoryOptions = {
  token: { image: catTokenImg, name: "NFT & Token Event" },
  art: { image: catArtImg, name: "Art & Exhbition" },
  music: { image: catMusicImg, name: "Music & Concert" },
  gaming: { image: catGameImg, name: "Gaming & Metaverse" },
  party: { image: catPartyImg, name: "Party & Festival" },
  sports: { image: catSportsImg, name: "Sports & Fitness" },
  dao: { image: catDaoImg, name: "DAO Governance" },
  meeting: { image: catMeetingImg, name: "Meeting & Conference" },
  study: { image: catStudyImg, name: "Study & Workshop" },
  leisure: { image: catLeisureImg, name: "Leisure & Tour" },
  fundraising: { image: catFundingImg, name: "Charity & Fundraising" },
  misc: { image: catMiscImg, name: "Miscellaneous" },
};

export const EventCategorySelectorOptions = [
  {
    value: "token",
    label: (
      <div>
        <FontAwesomeIcon icon="fa-coins" className="mr-2" />
        <span>NFT &amp; Token</span>
      </div>
    ),
  },
  {
    value: "art",
    label: (
      <div>
        <FontAwesomeIcon icon="fa-palette" className="mr-2" />
        <span>Art &amp; Exhbition</span>
      </div>
    ),
  },
  {
    value: "music",
    label: (
      <div>
        <FontAwesomeIcon icon="fa-music" className="mr-2" />
        <span>Music &amp; Concert</span>
      </div>
    ),
  },
  {
    value: "gaming",
    label: (
      <div>
        <FontAwesomeIcon icon="fa-gamepad" className="mr-2" />
        <span>Gaming &amp; Metaverse</span>
      </div>
    ),
  },
  {
    value: "party",
    label: (
      <div>
        <FontAwesomeIcon icon="fa-champagne-glasses" className="mr-2" />
        <span>Party &amp; Festival</span>
      </div>
    ),
  },
  {
    value: "sports",
    label: (
      <div>
        <FontAwesomeIcon icon="fa-person-swimming" className="mr-2" />
        <span>Sports &amp; Fitness</span>
      </div>
    ),
  },
  {
    value: "dao",
    label: (
      <div>
        <FontAwesomeIcon icon="fa-lightbulb" className="mr-2" />
        <span>DAO Governance</span>
      </div>
    ),
  },
  {
    value: "meeting",
    label: (
      <div>
        <FontAwesomeIcon icon="fa-podcast" className="mr-2" />
        <span>Meeting &amp; Conference</span>
      </div>
    ),
  },
  {
    value: "study",
    label: (
      <div>
        <FontAwesomeIcon icon="fa-graduation-cap" className="mr-2" />
        <span>Study &amp; Workshop</span>
      </div>
    ),
  },
  {
    value: "leisure",
    label: (
      <div>
        <FontAwesomeIcon icon="fa-umbrella-beach" className="mr-2" />
        <span>Leisure &amp; Tour</span>
      </div>
    ),
  },
  {
    value: "fundraising",
    label: (
      <div>
        <FontAwesomeIcon icon="fa-hand-holding-heart" className="mr-2" />
        <span>Charity &amp; Fundraising</span>
      </div>
    ),
  },
  {
    value: "misc",
    label: (
      <div>
        <FontAwesomeIcon icon="fa-puzzle-piece" className="mr-2" />
        <span>Miscellaneous</span>
      </div>
    ),
  },
];

export const NFTSelectorOptions = [
  {
    value: "collectible",
    label: (
      <div>
        <span>Collectibles</span>
      </div>
    ),
  },
  {
    value: "art",
    label: (
      <div>
        <span>Art</span>
      </div>
    ),
  },
  {
    value: "music",
    label: (
      <div>
        <span>Music</span>
      </div>
    ),
  },
  {
    value: "pfp",
    label: (
      <div>
        <span>PFP</span>
      </div>
    ),
  },
  {
    value: "gaming",
    label: (
      <div>
        <span>Gaming</span>
      </div>
    ),
  },
  {
    value: "utility",
    label: (
      <div>
        <span>Utility</span>
      </div>
    ),
  },
  {
    value: "identity",
    label: (
      <div>
        <span>Identity</span>
      </div>
    ),
  },
  {
    value: "membership",
    label: (
      <div>
        <span>Membership</span>
      </div>
    ),
  },
  {
    value: "accessPass",
    label: (
      <div>
        <span>Access Pass</span>
      </div>
    ),
  },
  {
    value: "metaverse",
    label: (
      <div>
        <span>Metaverse</span>
      </div>
    ),
  },
  {
    value: "software",
    label: (
      <div>
        <span>Software Licence</span>
      </div>
    ),
  },
  {
    value: "party",
    label: (
      <div>
        <span>Party</span>
      </div>
    ),
  },
  {
    value: "3d",
    label: (
      <div>
        <span>3D NFT</span>
      </div>
    ),
  },
  {
    value: "sports",
    label: (
      <div>
        <span>Sports</span>
      </div>
    ),
  },
  {
    value: "dao",
    label: (
      <div>
        <span>DAO</span>
      </div>
    ),
  },
  {
    value: "anime",
    label: (
      <div>
        <span>Anime</span>
      </div>
    ),
  },
  {
    value: "fashion",
    label: (
      <div>
        <span>Fashion</span>
      </div>
    ),
  },
  {
    value: "realEstate",
    label: (
      <div>
        <span>Real Estate</span>
      </div>
    ),
  },
  {
    value: "entertainment",
    label: (
      <div>
        <span>Entertainment</span>
      </div>
    ),
  },
  {
    value: "realWorldAssets",
    label: (
      <div>
        <span>Real World Assets</span>
      </div>
    ),
  },
  {
    value: "physical",
    label: (
      <div>
        <span>Physical NFT</span>
      </div>
    ),
  },
  {
    value: "misc",
    label: (
      <div>
        <span>Miscellaneous</span>
      </div>
    ),
  },
];

export const TeamTypeSelectorOptions = [
  {
    value: "cohost",
    label: (
      <div>
        <span>Cohost</span>
      </div>
    ),
  },
  {
    value: "panelist",
    label: (
      <div>
        <span>Panelist</span>
      </div>
    ),
  },
];

export const DisableMaxUsageOption = {
  value: 0,
  label: (
    <div>
      <span>Disabled</span>
    </div>
  ),
};

export const InviteMaxUsageOptions = [
  {
    value: -1,
    label: (
      <div>
        <span>No Limit</span>
      </div>
    ),
  },
  {
    value: 1,
    label: (
      <div>
        <span>1 Use</span>
      </div>
    ),
  },
  {
    value: 5,
    label: (
      <div>
        <span>5 Uses</span>
      </div>
    ),
  },
  {
    value: 10,
    label: (
      <div>
        <span>10 Uses</span>
      </div>
    ),
  },
  {
    value: 25,
    label: (
      <div>
        <span>25 Uses</span>
      </div>
    ),
  },
  {
    value: 50,
    label: (
      <div>
        <span>50 Uses</span>
      </div>
    ),
  },
  {
    value: 100,
    label: (
      <div>
        <span>100 Uses</span>
      </div>
    ),
  },
  {
    value: 250,
    label: (
      <div>
        <span>250 Uses</span>
      </div>
    ),
  },
  {
    value: 500,
    label: (
      <div>
        <span>500 Uses</span>
      </div>
    ),
  },
];

export const InviteExpireOptions = [
  {
    value: -1,
    label: (
      <div>
        <span>Never Expire</span>
      </div>
    ),
  },
  {
    value: 60 * 1000,
    label: (
      <div>
        <span>1 Minutes</span>
      </div>
    ),
  },
  {
    value: 5 * 60 * 1000,
    label: (
      <div>
        <span>5 Minutes</span>
      </div>
    ),
  },
  {
    value: 10 * 60 * 1000,
    label: (
      <div>
        <span>10 Minutes</span>
      </div>
    ),
  },
  {
    value: 30 * 60 * 1000,
    label: (
      <div>
        <span>30 Minutes</span>
      </div>
    ),
  },
  {
    value: 60 * 60 * 1000,
    label: (
      <div>
        <span>1 Hour</span>
      </div>
    ),
  },
  {
    value: 2 * 60 * 60 * 1000,
    label: (
      <div>
        <span>2 Hours</span>
      </div>
    ),
  },
  {
    value: 6 * 60 * 60 * 1000,
    label: (
      <div>
        <span>6 Hours</span>
      </div>
    ),
  },
  {
    value: 12 * 60 * 60 * 1000,
    label: (
      <div>
        <span>12 Hours</span>
      </div>
    ),
  },
  {
    value: 24 * 60 * 60 * 1000,
    label: (
      <div>
        <span>1 Day</span>
      </div>
    ),
  },
  {
    value: 7 * 24 * 60 * 60 * 1000,
    label: (
      <div>
        <span>7 Days</span>
      </div>
    ),
  },
  {
    value: 30 * 24 * 60 * 60 * 1000,
    label: (
      <div>
        <span>30 Days</span>
      </div>
    ),
  },
];

export const QuillEditorModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
  ],
};

export const QuillEditorFormats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
];

export const USDateShortOption = {
  day: "numeric",
  month: "short",
};

export const USDateOption = {
  day: "numeric",
  month: "short",
  year: "numeric",
};

export const USDateOptionTZ = {
  day: "numeric",
  month: "short",
  year: "numeric",
  timeZoneName: "short",
};

export const USDateTimeOption = {
  day: "2-digit",
  month: "short",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

export const USDateTimeShortOption = {
  day: "2-digit",
  month: "short",
  hour: "2-digit",
  minute: "2-digit",
};

export const CreatorRoleTypes = ["creator", "admin"];
export const AdminRoleTypes = ["admin"];
export const UserRoleTypes = ["user", "creator", "admin"];
export const UserRoleStatus = ["active", "pending", "rejected", "inactive"];
export const EventRoles = ["owner", "cohost", "panelist"];
export const EventRoleStatus = ["active", "pending", "rejected", "inactive"];

export const VerifiedUserTypes = ["creator", "brand"];
export const VerifiedUserStatus = ["active", "pending", "rejected", "inactive"];

export const SocialShareOptions = [
  "email",
  "telegram",
  "whatsapp",
  "messenger",
  "facebook",
  "linkedin",
  "twitter",
];
export const SocialShareMessage =
  "Join the event on RealPass and claim an NFT - realpass.xyz";
export const SocialShareTitle = "Join the event on RealPass and claim an #NFT";
export const SocialShareSubject = "Check out the email on RealPass";
export const SocialShareUsername = "realpassxyz";

export const DisclaimerShort =
  "All projects must be in \
compliance with laws and may not be used \
for securities, ICO, or gambling. \
Creator is solely responsible for \
meeting all compliance requirements and \
providing full disclosure to users. Your \
use of service is subject to the \
RealPass";
