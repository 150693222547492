import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useNavigate, Navigate } from "react-router-dom";

import { AuthContext } from "Auth.js";

// reactstrap components
import {
  ButtonGroup,
  Button,
  Card,
  Container,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  CardImg,
  CardBody,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// core components
import TopNavbar from "components/Navbars/TopNavbar.js";

import Loader from "components/Loaders/Loader.js";
import LoaderDots from "components/Loaders/LoaderDots.js";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";

import SocialLinksList from "components/Lists/SocialLinksList.js";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
  useNetwork,
  useSignMessage,
} from "wagmi";

import Web3 from "web3";
import { ethers } from "ethers";

import Avatar from "boring-avatars";

import FirebaseInit from "utils/FirebaseInit.js";

import {
  truncateAddressShort,
  truncateText,
  notify,
  validEthAddress,
  formatPrettyNumber,
  NoDataText,
} from "utils/Utils.js";

import {
  authHttpConfig,
  countFollowers,
  getProfile,
  getProfileWeb3,
  followUser,
  unfollowUser,
  countFollows,
  countFollowsWeb3,
  resolveEns,
  listCreatedEvents,
} from "utils/Api.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import InfiniteScroll from "react-infinite-scroll-component";

const defaultProfileImg = require("assets/img/default/profile.svg");
const defaultBannerImg = require("assets/img/bg/abstract-line-01.svg");

const {
  defaultBoxGrayImg,
  verifiedImg,
  ethImg,
  nsfwImg,
  ethWhiteImg,
  polygonImg,
  polygonWhiteImg,
  mmImg,
  ethLogoColor,
  polygonLogoColor,
  calImg,
  gCalImg,
  outlookImg,
  ChainLogos,
  ChainOptions,
  EventCategoryOptions,
  USDateOption,
  USDateTimeOption,
  USDateTimeShortOption,
  UserRoleTypes,
  CreatorRoleTypes,
  UserRoleStatus,
  VerifiedUserTypes,
  VerifiedUserStatus,
  SocialShareOptions,
  SocialShareMessage,
  SocialShareTitle,
  SocialShareSubject,
  SocialShareUsername,
  DisclaimerShort,
  DefaultPageSize,
} = require("utils/Variables.js");

const {
  REACT_APP_HOST,
  REACT_APP_API_HOST,
  REACT_APP_GOODLE_RECAPTCHA_CHECKBOX_KEY,
} = process.env;

export default function PublicProfile() {
  const navigate = useNavigate();

  const { currentUser, currentAuthToken, currentUserInfo, setCurrentUserInfo } =
    useContext(AuthContext);

  const {
    address: wagmiAddress,
    connector: wagmiConnector,
    isConnected: isWagmiConnected,
  } = useAccount();

  const { chain } = useNetwork();
  const { signMessageAsync } = useSignMessage();
  const { data: ensAvatar } = useEnsAvatar({ web3id });
  const { data: ensName } = useEnsName({ web3id });

  const {
    connect: wagmiConnect,
    connectors: wagmiConnectors,
    error: wagmiError,
    isLoading: isWagmiLoading,
    pendingConnector: pendingWagmiConnector,
  } = useConnect();

  const { disconnect: wagmiDisconnect } = useDisconnect({
    onError(error) {
      console.error("Error ", error);
    },
  });

  const domain = "RealPass";
  const origin = REACT_APP_HOST;
  const href = window.location.href;

  const { web3id } = useParams();

  const pageSize = DefaultPageSize;

  const [pageLoading, setPageLoading] = useState(false);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [followLoading, setFollowLoading] = useState(false);

  const [resolvedAddress, setResolvedAddress] = useState();

  const [userId, setUserId] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [bannerUrl, setBannerUrl] = useState();
  const [name, setName] = useState();
  const [bio, setBio] = useState();
  const [links, setLinks] = useState();

  const [followers, setFollowers] = useState();
  const [followings, setFollowings] = useState();
  const [followed, setFollowed] = useState(false);
  const [followMe, setFollowMe] = useState(false);

  const [isVerifiedUser, setIsVerifiedUser] = useState(false);

  const [createdPasses, setCreatedPasses] = useState([]);

  const [createdEvents, setCreatedEvents] = useState([]);
  const [moreCreatedEvents, setMoreCreatedEvents] = useState(true);
  const [createdEventsPageIndex, setCreatedEventsPageIndex] = useState(0);

  const [createdRaffles, setCreatedRaffles] = useState([]);

  const [unfollowModalOpen, setUnfollowModalOpen] = useState(false);

  // Supports ENS
  const resolveWeb3Address = async () => {
    try {
      setPageLoading(true);
      if (web3id) {
        let lowerAddress = web3id.toLowerCase();
        let isAddress = validEthAddress(lowerAddress);

        if (!isAddress) {
          const { address } = await resolveEns(web3id, null);

          lowerAddress = address?.toLowerCase();
          isAddress = validEthAddress(lowerAddress);

          // Not address and can not be resolved
          if (!isAddress) {
            navigate("/404");
          }
        }
        setResolvedAddress(lowerAddress);

        await fetchProfile(lowerAddress);
      }

      setPageLoading(false);
    } catch (err) {
      setPageLoading(false);
      console.error(err.message);
    }
  };

  const fetchProfile = async (address) => {
    try {
      const profile = await getProfileWeb3(currentAuthToken, address);

      setUserId(profile?.userId);
      setImageUrl(profile?.imageUrl);
      setBannerUrl(profile?.bannerUrl);
      setName(profile?.name);
      setBio(profile?.bio);
      setLinks(profile?.links);
      setIsVerifiedUser(profile?.isVerifiedUser);
      setFollowed(profile?.isFollowedByUser);
      setFollowMe(profile?.isFollowingUser);
      setFollowings(profile?.totalFollowings);
      setFollowers(profile?.totalFollowers);

      await fetchCreatedEvents(address);
    } catch (err) {
      console.error(err.message);
    }
  };

  const toggleFollow = async (followFlag) => {
    if (currentUser) {
      try {
        setFollowLoading(true);

        if (followFlag === followed) {
          return;
        }

        let isFollowing;
        let currentFollowings = currentUserInfo?.followings || [];

        if (followFlag) {
          isFollowing = await followUser(currentAuthToken, userId);

          if (isFollowing) {
            currentFollowings.push(userId);
          }
        } else {
          isFollowing = await unfollowUser(currentAuthToken, userId);

          if (!isFollowing) {
            currentFollowings = currentFollowings.filter(
              (item) => item !== userId
            );
          }
        }

        setCurrentUserInfo({
          ...currentUserInfo,
          followings: currentFollowings,
        });

        const res = await countFollows(userId);
        setFollowers(res?.totalFollowers || 0);
        setFollowings(res?.totalFollowings || 0);

        setFollowed(isFollowing);
      } catch (err) {
        console.error(err.message);
      } finally {
        setFollowLoading(false);
      }
    }
  };

  const fetchCreatedEvents = async (address) => {
    try {
      setItemsLoading(true);

      const toSkip = createdEventsPageIndex * pageSize;

      const { events } = await listCreatedEvents(address, pageSize, toSkip);

      const newList = createdEvents;

      for (const i of events) {
        const start = i.startDateTime;

        const ended = i?.endDateTime <= new Date().getTime();

        newList.push({
          id: i?.eventId,
          name: i?.name,
          image: i?.imageUrl,
          category: EventCategoryOptions[i?.category || "misc"],
          blockchain: ChainOptions[i?.blockchain],
          start: start,
          virtual: i?.isVirtual,
          location: i?.location,
          free: i?.isFree,
          isTokengated: i?.isTokengated,
          private: i?.isPrivate,
          published: i?.isPublished,
          nsfw: i?.isNSFW,
          public: i?.isPublic,
          ended: ended,
        });
      }

      setCreatedEvents([...newList]);

      if (events?.length > 0) {
        setMoreCreatedEvents(true);
      } else {
        setMoreCreatedEvents(false);
      }
    } catch (err) {
      console.error(err.message);
      notify("Unable to fetch events.", "error");
    } finally {
      setItemsLoading(false);
    }
  };

  // const showCreatedPasses = () => {
  //   return createdPasses.map((item, index) => {
  //     return <></>;
  //   });
  // };

  const showCreatedEvents = () => {
    return createdEvents.map((item, index) => {
      return (
        <Col
          className="mt-1 mb-1 p-0"
          xl="3"
          lg="3"
          md="4"
          sm="6"
          xs="6"
          key={index}
        >
          <div className="card-thumbnail m-2 events-cards" lg="4">
            {item?.nsfw && (
              <>
                <img
                  id={`nsfwBadge-${index}`}
                  src={nsfwImg}
                  className="nsfw-thumbnail-mark"
                  style={{ cursor: "pointer" }}
                />

                <UncontrolledTooltip
                  placement="top"
                  target={`nsfwBadge-${index}`}
                  trigger="hover"
                >
                  <span className="text-capitalize">Not Suitable For Work</span>
                </UncontrolledTooltip>
              </>
            )}
            <a href={"/event/" + item?.id}>
              <div className="card-img-thumbnail">
                <CardImg src={item?.image || defaultBoxGrayImg} top />{" "}
              </div>
            </a>
            <CardBody className="text-left events-cards-body">
              <a href={"/event/" + item?.id}>
                <div className="title-thumbnail mb-2">
                  <strong>{item?.name}</strong>
                </div>
                {/* <div className="mt-1 mb-1">
                  <small>
                    by <strong>{name}</strong>
                    {isVerifiedUser && (
                      <img
                        className="realpass-verified-name-suffix-sm ml-1 mb-1"
                        src={verifiedImg}
                      />
                    )}
                  </small>
                </div> */}
              </a>
              {/* <img
                id={`blockchainBadge-${index}`}
                className="chain-icon-sm mt-1 mb-1"
                src={item?.blockchain?.image}
                style={{ cursor: "pointer" }}
              /> 
              <UncontrolledTooltip
                placement="top"
                target={`blockchainBadge-${index}`}
                trigger="hover"
              >
                <span className="text-capitalize">
                  {item?.blockchain?.name}
                </span>
              </UncontrolledTooltip>*/}
              <img
                id={`categoryBadge-${index}`}
                className="category-icon-sm mt-1 mb-1 ml-1"
                src={item?.category?.image}
                style={{ cursor: "pointer" }}
              />
              <UncontrolledTooltip
                placement="top"
                target={`categoryBadge-${index}`}
                trigger="hover"
              >
                <span className="text-capitalize">{item?.category?.name}</span>
              </UncontrolledTooltip>
              <Badge
                id={`eventBadge-${index}`}
                className="text-white mt-1 mb-1 ml-1"
                pill
                color="primary"
                style={{ cursor: "pointer" }}
              >
                <small>Event</small>
              </Badge>
              {/* {!item?.public && (
                <>
                  <Badge
                    id={`privateBadge-${index}`}
                    color="secondary"
                    pill
                    className="text-default mt-1 mb-1 ml-1"
                    style={{ cursor: "pointer" }}
                  >
                    <small>PVT</small>
                  </Badge>
                  <UncontrolledTooltip
                    placement="top"
                    target={`privateBadge-${index}`}
                    trigger="hover"
                  >
                    <span className="text-capitalize">Private Event</span>
                  </UncontrolledTooltip>
                </>
              )} */}
              {/* <Row className="mt-2 px-1">
                <Col className="mt-1 mb-1 px-2 col-xl-5 col-lg-5">
                  <small className="text-muted time-thumbnail">
                    <FontAwesomeIcon icon="fa-clock" className="mr-2" />
                    {item?.ended ? "ENDED" : item?.start || "TBA"}
                  </small>
                </Col>
                <Col className="mt-1 mb-1 px-2 col-xl-7 col-lg-7">
                  <small className="text-muted address-thumbnail">
                    {item?.virtual ? (
                      <i className="ni ni-planet mr-2 align-middle" />
                    ) : (
                      <FontAwesomeIcon
                        icon="fa-location-dot"
                        className="mr-2"
                      />
                    )}
                    {item?.location ? (
                      item?.virtual ? (
                        <>Virtual</>
                      ) : (
                        <>{item?.location}</>
                      )
                    ) : (
                      "TBA"
                    )}
                  </small>
                </Col>
              </Row> */}
            </CardBody>
          </div>
        </Col>
      );
    });
  };

  const showCreatedRaffles = () => {
    return createdRaffles.map((item, index) => {
      return <></>;
    });
  };

  const handleSelectTab = async (e) => {
    setPageLoading(true);

    if (e.currentTarget.attributes["aria-selected"].value === "false") {
      if (e.currentTarget.id === "tab:r0:0") {
        if (!createdEvents || createdEvents.length <= 0) {
          await fetchCreatedEvents();
        }
      }
    }

    setPageLoading(false);
  };

  useEffect(() => {
    resolveWeb3Address();
  }, []);

  useEffect(() => {
    if (resolvedAddress) {
      fetchCreatedEvents(resolvedAddress);
    }
  }, [createdEventsPageIndex]);

  return (
    <>
      <BarLoader
        loading={pageLoading}
        cssOverride={{
          width: "100%",
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          zIndex: "1031",
          backgroundColor: "#fff",
          opacity: "1",
        }}
      />
      <TopNavbar />
      <ReactNotifications />
      <div className="wrapper profile-page">
        <section className="section-profile-cover section-shaped my-0">
          <img
            alt="..."
            className="bg-image"
            src={bannerUrl || defaultBannerImg}
            style={{ width: "100%", opacity: "1" }}
          ></img>
        </section>
        <section className="section section-main">
          <Container>
            <div className="text-center" style={{ marginTop: "-150px" }}>
              {imageUrl ? (
                <img
                  alt="..."
                  className="rounded-circle profile-image"
                  src={imageUrl}
                />
              ) : ensAvatar ? (
                <img
                  src={ensAvatar}
                  alt="ENS Avatar"
                  className="rounded-circle profile-image"
                />
              ) : (
                <span className="rounded-circle">
                  <Avatar
                    size={150}
                    name={web3id}
                    square="false"
                    variant="ring"
                    colors={[
                      "#000000",
                      "#2F2F2F",
                      "#505050",
                      "#797979",
                      "#CECECE",
                    ]}
                    className="rounded-circle profile-image"
                  />
                </span>
              )}
              <div className="mt-3">
                <div className="mt--6 float-right">
                  <div id="followButton">
                    <Button
                      type="button"
                      className={`m-2 ${
                        followed
                          ? "btn-outline-secondary text-info"
                          : "btn-outline-info"
                      }`}
                      size="sm"
                      onClick={() => {
                        if (followed) {
                          setUnfollowModalOpen(!unfollowModalOpen);
                        } else {
                          toggleFollow(true);
                        }
                      }}
                      disabled={!currentUserInfo}
                    >
                      {currentUser ? (
                        followLoading ? (
                          <ScaleLoader
                            color="#eee"
                            loading={followLoading}
                            width="5px"
                            height="3px"
                            cssOverride={{
                              display: "inline",
                            }}
                          />
                        ) : followed ? (
                          "Following"
                        ) : (
                          "Follow"
                        )
                      ) : (
                        "follow"
                      )}
                    </Button>
                  </div>
                  {!currentUserInfo && (
                    <UncontrolledTooltip
                      placement="top"
                      target="followButton"
                      trigger="hover"
                    >
                      "Sign in and complete profile"
                    </UncontrolledTooltip>
                  )}
                </div>
                <span
                  className="display-4"
                  style={{
                    wordWrap: "break-word",
                  }}
                >
                  {!pageLoading && (name || "Unnamed")}
                  {isVerifiedUser && (
                    <img
                      className="realpass-verified-name-suffix-md ml-2 mb-1"
                      src={verifiedImg}
                    />
                  )}
                </span>

                {followMe && (
                  <sup>
                    <Badge size="sm" color="secondary" className="ml-2">
                      Follows You
                    </Badge>
                  </sup>
                )}
              </div>

              <div className="mt-3 mb-4 px-xl-9 px-lg-9 px-md-6 px-sm-2 px-xs-2">
                {bio}
              </div>
              <div className="font-weight-300">
                <Button
                  id="walletAddressButton"
                  className="btn-sm btn"
                  color="dark"
                  outline
                  type="button"
                  style={{ textTransform: "none" }}
                  onClick={() => navigator.clipboard.writeText(resolvedAddress)}
                >
                  <img className="icon-eth mr-2" alt="..." src={ethImg} />
                  <span className="wallet-field-sm">
                    {truncateAddressShort(resolvedAddress)}
                    <FontAwesomeIcon
                      icon="fa-clone"
                      className="ml-1 text-muted"
                    />
                  </span>
                </Button>
                <UncontrolledTooltip
                  deplay={30}
                  placement="top"
                  target="walletAddressButton"
                  trigger="click"
                >
                  Address Copied
                </UncontrolledTooltip>

                {links?.length > 0 && (
                  <SocialLinksList links={links} size="xl" />
                )}
              </div>

              <div className="mt-3">
                <small className="text-muted m-3">
                  <strong className="mr-1">
                    {formatPrettyNumber(followings)}
                  </strong>
                  <strong>Following</strong>
                </small>
                <small className="text-muted m-3">
                  <strong className="mr-1">
                    {formatPrettyNumber(followers)}
                  </strong>
                  <strong>Followers</strong>
                </small>
              </div>
            </div>

            <Tabs className="py-5 text-center">
              {/* <TabList>
                <Tab onClick={handleSelectTab}>
                  <span>Events</span>
                </Tab>
                <Tab onClick={handleSelectTab}>
                  <span>Passes</span>
                </Tab>
                <Tab onClick={handleSelectTab}>
                  <span>Raffles</span>
                </Tab>
              </TabList> */}
              <TabPanel>
                <div className="pt-2 pb-4 px-1">
                  <div className="text-left mb-2">
                    <h4 className="display-4 text-default">Events</h4>
                    <small>Created by {name || "Unnamed"}</small>
                  </div>
                  {(!createdEvents || createdEvents.length <= 0) &&
                  !itemsLoading ? (
                    <div className="mt-5">{NoDataText("lg", true)}</div>
                  ) : createdEvents?.length > 0 ? (
                    <InfiniteScroll
                      dataLength={createdEvents.length}
                      next={() => {
                        setCreatedEventsPageIndex((page) => page + 1);
                      }}
                      hasMore={moreCreatedEvents}
                    >
                      <Row className="mt-3 mb-5">{showCreatedEvents()}</Row>
                      <LoaderDots loading={itemsLoading} />
                    </InfiniteScroll>
                  ) : (
                    <LoaderDots loading={itemsLoading} />
                  )}
                </div>
              </TabPanel>
              {/* <TabPanel>
                <div className="pt-2 pb-4 px-1">
                  <div className="text-left">
                    <h4 className="display-4 text-default">Passes</h4>
                  </div>
                  {createdPasses.length <= 0 && !itemsLoading ? (
                    <>
                      <h4 className="mt-5 mb-5 text-muted">Coming soon.</h4>
                    </>
                  ) : (
                    <Row className="mt-1 mb-5">{showCreatedPasses()}</Row>
                  )}
                </div>
              </TabPanel>
              <TabPanel>
                <div className="pt-2 pb-4 px-1">
                  <div className="text-left">
                    <h4 className="display-4 text-default">Raffles</h4>
                  </div>
                  {createdRaffles.length <= 0 && !itemsLoading ? (
                    <h4 className="mt-5 mb-5 text-muted">Coming soon.</h4>
                  ) : (
                    <Row className="mt-1 mb-5">{showCreatedRaffles()}</Row>
                  )}
                </div>
              </TabPanel> */}
            </Tabs>

            <Modal
              toggle={() => setUnfollowModalOpen(!unfollowModalOpen)}
              isOpen={unfollowModalOpen}
            >
              <div className="modal-header">
                <span className="modal-title">
                  <strong>Unfollow {truncateText(name, 16)}?</strong>
                </span>

                <button
                  aria-label="Close"
                  className=" close"
                  type="button"
                  onClick={() => setUnfollowModalOpen(false)}
                >
                  <span aria-hidden={true}>
                    <FontAwesomeIcon icon="fa-xmark" />
                  </span>
                </button>
              </div>
              <ModalFooter>
                <Button
                  size="md"
                  color="primary"
                  type="button"
                  onClick={() => {
                    toggleFollow(false);
                    setUnfollowModalOpen(!unfollowModalOpen);
                  }}
                >
                  Unfollow
                </Button>
                <Button
                  size="md"
                  color="link"
                  type="button"
                  onClick={() => setUnfollowModalOpen(!unfollowModalOpen)}
                >
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          </Container>
        </section>
      </div>
    </>
  );
}
