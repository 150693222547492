import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { AuthContext } from "Auth.js";

// reactstrap components
import {
  ButtonGroup,
  Button,
  Card,
  Container,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  CardImg,
  CardBody,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardHeader,
} from "reactstrap";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// core components
import TopNavbar from "components/Navbars/TopNavbar.js";

import SocialLinksList from "components/Lists/SocialLinksList.js";

import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.compat.css";

import {
  truncateAddressShort,
  notify,
  formatPrettyNumber,
  NoDataText,
} from "utils/Utils.js";

import {
  createSiweMessage,
  eventUrlExists,
  countFollowers,
  listMyCreatedEvents,
  listMyJoinedEvents,
} from "utils/Api.js";

import axios from "axios";

import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
  useNetwork,
  useSignMessage,
} from "wagmi";

import { ethers } from "ethers";

import Avatar from "boring-avatars";

import FirebaseInit from "utils/FirebaseInit.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import InfiniteScroll from "react-infinite-scroll-component";

import Loader from "components/Loaders/Loader.js";
import LoaderDots from "components/Loaders/LoaderDots.js";

const {
  defaultBoxGrayImg,
  verifiedImg,
  ethImg,
  nsfwImg,
  mmImg,
  wcImg,
  pwImg,
  ethWhiteImg,
  ethLogoColor,
  polygonImg,
  polygonWhiteImg,
  EventCategoryOptions,
  ChainOptions,
  DefaultPageSize,
} = require("utils/Variables.js");

const {
  REACT_APP_HOST,
  REACT_APP_API_HOST,
  REACT_APP_GOODLE_RECAPTCHA_CHECKBOX_KEY,
} = process.env;

function Account() {
  const { currentUser, currentAuthToken, currentUserInfo, setCurrentUserInfo } =
    useContext(AuthContext);

  const hasCreatorRole = currentUserInfo?.hasCreatorRole;
  const isVerifiedUser = currentUserInfo?.isVerifiedUser;

  const [isNewUser, setIsNewUser] = useState(
    !currentUserInfo?.ethAddress || !currentUser?.email
  );

  const {
    address: wagmiAddress,
    connector: wagmiConnector,
    isConnected: isWagmiConnected,
  } = useAccount();

  const { chain } = useNetwork();
  const { signMessageAsync } = useSignMessage();
  const { data: ensAvatar } = useEnsAvatar({ wagmiAddress });
  const { data: ensName } = useEnsName({ wagmiAddress });
  const {
    connect: wagmiConnect,
    connectors: wagmiConnectors,
    error: wagmiError,
    isLoading: isWagmiLoading,
    pendingConnector: pendingWagmiConnector,
  } = useConnect();

  const { disconnect: wagmiDisconnect } = useDisconnect({
    onError(error) {
      console.error("Error ", error);
    },
  });

  const domain = "RealPass";
  const origin = REACT_APP_HOST;
  const href = window.location.href;

  const pageSize = DefaultPageSize;

  const [pageLoading, setPageLoading] = useState(false);
  const [itemsLoading, setItemsLoading] = useState(false);

  //   const [ethAddress, setEthAddress] = useState(currentUserInfo?.ethAddress);
  const [image, setImage] = useState();
  const [bannerUrl, setBannerUrl] = useState();
  const [name, setName] = useState();
  const [bio, setBio] = useState();

  const [links, setLinks] = useState();

  const [followings, setFollowings] = useState(
    currentUserInfo?.followings?.length || 0
  );
  const [followers, setFollowers] = useState(currentUserInfo?.followers || 0);

  const [ownedPasses, setOwnedPasses] = useState([]);
  const [createdPasses, setCreatedPasses] = useState([]);

  const [eventTab, setEventTab] = useState(0); // First Tab

  const [joinedEvents, setJoinedEvents] = useState([]);
  const [moreJoinedEvents, setMoreJoinedEvents] = useState(true);
  const [joinedEventsPageIndex, setJoinedEventsPageIndex] = useState(0);

  const [showAccessOption, setShowAccessOption] = useState("active");

  const [createdEvents, setCreatedEvents] = useState([]);
  const [moreCreatedEvents, setMoreCreatedEvents] = useState(true);
  const [createdEventsPageIndex, setCreatedEventsPageIndex] = useState(0); // First Page

  const [joinedRaffles, setJoinedRaffles] = useState([]);
  const [createdRaffles, setCreatedRaffles] = useState([]);

  const fetchOwnedPasses = async () => {};

  const fetchJoinedEvents = async () => {
    try {
      // if (joinedEventsPageIndex) {
      // setItemsLoading(true);
      // } else {
      //   setPageLoading(true);
      // }

      // if (showAccessOption === "active") {
      //   params.isValid = true;
      // }

      setItemsLoading(true);

      const toSkip = joinedEventsPageIndex * pageSize;

      const { events } = await listMyJoinedEvents(
        currentAuthToken,
        pageSize,
        toSkip
      );

      const newList = joinedEvents;

      for (const i of events) {
        const start = i?.startDateTime;

        const ended = i?.endDateTime <= new Date().getTime();

        newList.push({
          id: i?.eventId,
          name: i?.name,
          image: i?.imageUrl,
          category: EventCategoryOptions[i?.category || "misc"],
          blockchain: ChainOptions[i?.blockchain],
          start: start,
          virtual: i?.isVirtual,
          location: i?.location,
          free: i?.isFree,
          isTokengated: i?.isTokengated,
          private: i?.isPrivate,
          published: i?.isPublished,
          nsfw: i?.isNSFW,
          public: i?.isPublic,
          ended: ended,
          isValid: !i?.isCancelled,
        });
      }

      setJoinedEvents([...newList]);

      if (events?.length > 0) {
        setMoreJoinedEvents(true);
      } else {
        setMoreJoinedEvents(false);
      }
    } catch (err) {
      console.error(err.message);
      notify("Unable to fetch joined events.", "error");
    } finally {
      setItemsLoading(false);
      setPageLoading(false);
    }
  };

  const fetchJoinedRaffles = async () => {};

  const fetchCreatedEvents = async (address) => {
    try {
      setItemsLoading(true);

      const toSkip = createdEventsPageIndex * pageSize;

      const { events } = await listMyCreatedEvents(
        currentAuthToken,
        pageSize,
        toSkip
      );

      const newList = createdEvents;

      for (const i of events) {
        const start = i?.startDateTime;

        const ended = i?.endDateTime <= new Date().getTime();

        newList.push({
          id: i?.eventId,
          name: i?.name,
          image: i?.imageUrl,
          category: EventCategoryOptions[i?.category || "misc"],
          blockchain: ChainOptions[i?.blockchain],
          start: start,
          virtual: i?.isVirtual,
          location: i?.location,
          free: i?.isFree,
          isTokengated: i?.isTokengated,
          private: i?.isPrivate,
          published: i?.isPublished,
          nsfw: i?.isNSFW,
          public: i?.isPublic,
          ended: ended,
        });
      }

      setCreatedEvents([...newList]);

      if (events?.length > 0) {
        setMoreCreatedEvents(true);
      } else {
        setMoreCreatedEvents(false);
      }
    } catch (err) {
      console.error(err.message);
      notify("Unable to fetch events.", "error");
    } finally {
      setItemsLoading(false);
    }
  };

  const showOwnedPasses = () => {
    return ownedPasses.map((item, index) => {
      return <></>;
    });
  };

  const showJoinedEvents = () => {
    return joinedEvents.map((item, index) => {
      return (
        <Col
          className="mt-1 mb-1 p-0"
          xl="3"
          lg="3"
          md="4"
          sm="6"
          xs="6"
          key={index}
        >
          <Card
            className={`shadow card-thumbnail m-2 access-cards ${
              !item?.isValid && " access-cards-invalid"
            }`}
            lg="4"
          >
            {item?.nsfw && (
              <>
                <img
                  id={`nsfwBadge-${index}`}
                  src={nsfwImg}
                  className="nsfw-thumbnail-mark"
                  style={{ cursor: "pointer" }}
                />

                <UncontrolledTooltip
                  placement="top"
                  target={`nsfwBadge-${index}`}
                  trigger="hover"
                >
                  <span className="text-capitalize">Not Suitable For Work</span>
                </UncontrolledTooltip>
              </>
            )}
            <a href={"/myaccess?event=" + item?.id}>
              <div className="card-img-thumbnail">
                <CardImg
                  src={item?.image || defaultBoxGrayImg}
                  className={`${!item?.isValid && "access-cards-img-invalid"}`}
                  top
                />{" "}
              </div>
            </a>
            <CardBody className="text-left access-cards-body">
              <a href={"/myaccess?event=" + item?.id}>
                <div className="title-thumbnail">
                  <strong>{item?.name}</strong>
                </div>
                {/* <div className="mt-1 mb-1">
                  <small>
                    by <strong>{item?.by}</strong>
                    {item?.verified && (
                      <img
                        className="realpass-verified-name-suffix-sm ml-1 mb-1"
                        src={verifiedImg}
                      />
                    )}
                  </small>
                </div> */}
              </a>
              {/* <img
                id={`blockchainBadge-${index}`}
                className="chain-icon-sm mt-1 mb-1"
                src={item?.blockchain?.image}
                style={{ cursor: "pointer" }}
              />
              <UncontrolledTooltip
                placement="top"
                target={`blockchainBadge-${index}`}
                trigger="hover"
              >
                <span className="text-capitalize">
                  {item?.blockchain?.name}
                </span>
              </UncontrolledTooltip> */}
              <img
                id={`categoryBadge-${index}`}
                className="category-icon-sm mt-1 mb-1 ml-1"
                src={item?.category?.image}
                style={{ cursor: "pointer" }}
              />
              <UncontrolledTooltip
                placement="top"
                target={`categoryBadge-${index}`}
                trigger="hover"
              >
                <span className="text-capitalize">{item?.category?.name}</span>
              </UncontrolledTooltip>
              <Badge
                id={`eventBadge-${index}`}
                className="text-white mt-1 mb-1 ml-1"
                pill
                color={`${item?.isValid ? "primary" : "warning"}`}
                style={{ cursor: "pointer" }}
              >
                <small>{item?.isValid ? "Event" : "Inactive"}</small>
              </Badge>
              {/* {!item?.public && (
                <>
                  <Badge
                    id={`privateBadge-${index}`}
                    color="secondary"
                    pill
                    className="text-default mt-1 mb-1 ml-1"
                    style={{ cursor: "pointer" }}
                  >
                    <small>PVT</small>
                  </Badge>
                  <UncontrolledTooltip
                    placement="top"
                    target={`privateBadge-${index}`}
                    trigger="hover"
                  >
                    <span className="text-capitalize">Private Event</span>
                  </UncontrolledTooltip>
                </>
              )} */}

              {/* <Row className="mt-2">
                <Col className="mt-1 mb-1 px-2 col-xl-5 col-lg-5">
                  <small className="text-muted time-thumbnail">
                    <FontAwesomeIcon icon="fa-clock" className="mr-2" />
                    {item?.ended ? "ENDED" : item?.start || "TBA"}
                  </small>
                </Col>
                <Col className="mt-1 mb-1 px-2 col-xl-7 col-lg-7">
                  <small className="text-muted address-thumbnail">
                    {item?.virtual ? (
                      <i className="ni ni-planet mr-2 align-middle" />
                    ) : (
                      <FontAwesomeIcon
                        icon="fa-location-dot"
                        className="mr-2"
                      />
                    )}
                    {item?.location ? (
                      item?.virtual ? (
                        <>Virtual</>
                      ) : (
                        <>{item?.location}</>
                      )
                    ) : (
                      "TBA"
                    )}
                  </small>
                </Col>
              </Row> */}
            </CardBody>
          </Card>
        </Col>
      );
    });
  };

  const showJoinedRaffles = () => {
    return joinedRaffles.map((item, index) => {
      return <></>;
    });
  };

  const showCreatedPasses = () => {
    return createdPasses.map((item, index) => {
      return <></>;
    });
  };

  const showCreatedEvents = () => {
    return createdEvents.map((item, index) => {
      return (
        <Col
          className="mt-1 mb-1 p-0"
          xl="3"
          lg="3"
          md="4"
          sm="6"
          xs="6"
          key={index}
        >
          <Card
            className={`shadow card-thumbnail m-2 access-cards ${
              !item?.published && "event-draft"
            }`}
            lg="4"
          >
            {item?.nsfw && (
              <>
                <img
                  id={`nsfwBadge-${index}`}
                  src={nsfwImg}
                  className="nsfw-thumbnail-mark"
                  style={{ cursor: "pointer" }}
                />

                <UncontrolledTooltip
                  placement="top"
                  target={`nsfwBadge-${index}`}
                  trigger="hover"
                >
                  <span className="text-capitalize">Not Suitable For Work</span>
                </UncontrolledTooltip>
              </>
            )}
            <a href={`/event/${item?.id}`}>
              <div
                className={`card-img-thumbnail ${
                  !item?.published && "event-draft"
                }`}
              >
                <CardImg src={item?.image || defaultBoxGrayImg} top />{" "}
              </div>
            </a>
            <CardBody className="text-left events-cards-body">
              <a href={`/event/${item?.id}`}>
                <div className="title-thumbnail mb-2">
                  <strong>{item?.name}</strong>
                </div>
                {/* <div className="mt-1 mb-1">
                  <small>
                    by <strong>{name}</strong>
                    {isVerifiedUser && (
                      <img
                        className="realpass-verified-name-suffix-sm ml-1 mb-1"
                        src={verifiedImg}
                      />
                    )}
                  </small>
                </div> */}
              </a>
              {/* <img
                id={`blockchainBadge-${index}`}
                className="chain-icon-sm mt-1 mb-1"
                src={item?.blockchain?.image}
                style={{ cursor: "pointer" }}
              />
              <UncontrolledTooltip
                placement="top"
                target={`blockchainBadge-${index}`}
                trigger="hover"
              >
                <span className="text-capitalize">
                  {item?.blockchain?.name}
                </span>
              </UncontrolledTooltip> */}
              <img
                id={`categoryBadge-${index}`}
                className="category-icon-sm mt-1 mb-1 ml-1"
                src={item?.category?.image}
                style={{ cursor: "pointer" }}
              />
              <UncontrolledTooltip
                placement="top"
                target={`categoryBadge-${index}`}
                trigger="hover"
              >
                <span className="text-capitalize">{item?.category?.name}</span>
              </UncontrolledTooltip>
              <Badge
                id={`eventBadge-${index}`}
                className="text-white mt-1 mb-1 ml-1"
                pill
                color={item?.published ? "primary" : "orange"}
                style={{ cursor: "pointer" }}
              >
                <small>{item?.published ? "Event" : "Draft"}</small>
              </Badge>
              {/* {!item?.public && (
                <>
                  <Badge
                    id={`privateBadge-${index}`}
                    color="secondary"
                    pill
                    className="text-default mt-1 mb-1 ml-1"
                    style={{ cursor: "pointer" }}
                  >
                    <small>PVT</small>
                  </Badge>
                  <UncontrolledTooltip
                    placement="top"
                    target={`privateBadge-${index}`}
                    trigger="hover"
                  >
                    <span className="text-capitalize">Private Event</span>
                  </UncontrolledTooltip>
                </>
              )} */}
              {/* <Row className="mt-2 px-1">
                <Col className="mt-1 mb-1 px-2 col-xl-5 col-lg-5">
                  <small className="text-muted time-thumbnail">
                    <FontAwesomeIcon icon="fa-clock" className="mr-2" />
                    {item?.ended ? "ENDED" : item?.start || "TBA"}
                  </small>
                </Col>
                <Col className="mt-1 mb-1 px-2 col-xl-7 col-lg-7">
                  <small className="text-muted address-thumbnail">
                    {item?.virtual ? (
                      <i className="ni ni-planet mr-2 align-middle" />
                    ) : (
                      <FontAwesomeIcon
                        icon="fa-location-dot"
                        className="mr-2"
                      />
                    )}
                    {item?.location ? (
                      item?.virtual ? (
                        <>Virtual</>
                      ) : (
                        <>{item?.location}</>
                      )
                    ) : (
                      "TBA"
                    )}
                  </small>
                </Col>
              </Row> */}
            </CardBody>
          </Card>
        </Col>
      );
    });
  };

  const showCreatedRaffles = (items) => {
    return items.map((item, index) => {
      return <></>;
    });
  };

  const handleSelectTab = async (e) => {
    setPageLoading(true);

    if (e.currentTarget.attributes["aria-selected"].value === "false") {
      if (e.currentTarget.id === "tab:r0:0") {
        setEventTab(0);

        if (!joinedEvents || joinedEvents.length <= 0) {
          await fetchJoinedEvents();
        }
      }
    }

    setPageLoading(false);
  };

  const toggleEventNavs = async (index) => {
    setPageLoading(true);

    if (index === 0 && index !== eventTab) {
      setEventTab(index);

      if (!joinedEvents || joinedEvents.length <= 0) {
        await fetchJoinedEvents();
      }
    }

    if (index === 1 && index !== eventTab) {
      setEventTab(index);

      if (!createdEvents || createdEvents.length <= 0) {
        await fetchCreatedEvents();
      }
    }

    setPageLoading(false);
  };

  // Maybe not need to fetch on page load
  // useEffect(() => {
  //   fetchJoinedEvents();
  // }, []);

  // For infinite scroll pagination
  useEffect(() => {
    if (eventTab === 0) {
      fetchJoinedEvents();
    }
  }, [joinedEventsPageIndex, showAccessOption]);

  // For infinite scroll pagination
  useEffect(() => {
    if (eventTab === 1) {
      fetchCreatedEvents();
    }
  }, [createdEventsPageIndex]);

  return (
    <>
      <TopNavbar />
      <ReactNotifications />
      <section className="section section-shaped section-main">
        {/* <Loader loading={pageLoading} /> */}
        <Container>
          <h3 className="mb-3">
            <strong>Account</strong>
            {currentUserInfo?.ethAddress && (
              <Button
                size="sm"
                outline
                color="link"
                href={`/${currentUserInfo?.ethAddress || "unnamed"}`}
                className="ml-2"
              >
                <>
                  <small>(Go to Public Page)</small>
                </>
              </Button>
            )}
          </h3>
          <Card className="shadow">
            {currentUserInfo?.bannerUrl && (
              <img
                alt="..."
                className="bg-image"
                src={currentUserInfo?.bannerUrl}
                style={{
                  width: "100%",
                  height: "180px",
                  opacity: "1",
                  objectFit: "cover",
                  borderRadius: "10px 10px 0 0",
                  marginBottom: "-120px",
                  position: "relative",
                  zIndex: "0",
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
              />
            )}
            <CardBody>
              <div>
                <div className="text-center mt-3">
                  {currentUserInfo?.imageUrl ? (
                    <img
                      alt="..."
                      className="rounded-circle profile-image"
                      src={currentUserInfo?.imageUrl}
                      style={{
                        position: "relative",
                        zIndex: "0",
                      }}
                    />
                  ) : ensAvatar ? (
                    <img
                      src={ensAvatar}
                      alt="ENS Avatar"
                      className="rounded-circle profile-image"
                      style={{
                        position: "relative",
                        zIndex: "0",
                      }}
                    />
                  ) : (
                    <span
                      className="rounded-circle"
                      style={{
                        position: "relative",
                        zIndex: "0",
                      }}
                    >
                      <Avatar
                        size={150}
                        name={currentUserInfo?.ethAddress}
                        square="false"
                        variant="ring"
                        colors={[
                          "#000000",
                          "#2F2F2F",
                          "#505050",
                          "#797979",
                          "#CECECE",
                        ]}
                        className="rounded-circle profile-image"
                      />
                    </span>
                  )}
                  <div className="my-3 h4">
                    <a
                      href={`/profile/${currentUserInfo?.ethAddress}`}
                      id="publicPageLink"
                    >
                      <strong>
                        {!pageLoading && (currentUserInfo?.name || "Unnamed")}
                      </strong>
                    </a>
                    {isVerifiedUser && (
                      <img
                        className="realpass-verified-name-suffix-md ml-2 mb-1"
                        src={verifiedImg}
                      />
                    )}
                    <UncontrolledTooltip
                      deplay={30}
                      placement="top"
                      target="publicPageLink"
                      trigger="hover"
                    >
                      Go to Public Page
                    </UncontrolledTooltip>

                    <a
                      className="text-default ml-2 icon-link"
                      color="link"
                      href="/settings"
                    >
                      <small>
                        <FontAwesomeIcon
                          icon="fa-pencil"
                          className="text-muted"
                        />
                      </small>
                    </a>
                  </div>
                  <div className="mt-3 mb-4 px-xl-9 px-lg-9 px-md-6 px-sm-2 px-xs-2">
                    {currentUserInfo?.bio}
                  </div>
                  <div className="font-weight-300 m-3">
                    <Button
                      id="walletAddressButton"
                      className="btn-sm btn"
                      color="dark"
                      outline
                      type="button"
                      style={{ textTransform: "none" }}
                      onClick={() =>
                        navigator.clipboard.writeText(
                          currentUserInfo?.ethAddress
                        )
                      }
                    >
                      <img className="icon-eth mr-2" alt="..." src={ethImg} />
                      <span className="wallet-field-sm">
                        {truncateAddressShort(currentUserInfo?.ethAddress)}
                        <FontAwesomeIcon
                          icon="fa-clone"
                          className="ml-1 text-muted"
                        />
                      </span>
                    </Button>
                    <UncontrolledTooltip
                      deplay={30}
                      placement="top"
                      target="walletAddressButton"
                      trigger="focus"
                    >
                      Address Copied
                    </UncontrolledTooltip>
                    {currentUserInfo?.links?.length > 0 && (
                      <SocialLinksList links={currentUserInfo?.links} />
                    )}
                  </div>

                  <div className="mt-3">
                    <small className="text-muted m-3">
                      <strong className="mr-1">
                        {formatPrettyNumber(followings)}
                      </strong>
                      <strong>Following</strong>
                    </small>
                    <small className="text-muted m-3">
                      <strong className="mr-1">
                        {formatPrettyNumber(followers)}
                      </strong>
                      <strong>Followers</strong>
                    </small>
                  </div>
                </div>
                <Tabs className="py-5 text-center">
                  {/* <TabList>
                    <Tab onClick={handleSelectTab}>
                      <span>Events</span>
                    </Tab>
                    <Tab onClick={handleSelectTab}>
                      <span>Passes</span>
                    </Tab>
                    <Tab onClick={handleSelectTab}>
                      <span>Raffles</span>
                    </Tab>
                  </TabList> */}
                  <TabPanel>
                    <hr />
                    <div className="pt-2 pb-4 px-1">
                      <div className="text-left">
                        <h4 className="display-4 text-default">Events</h4>
                      </div>
                      <div className="justify-content-right text-right">
                        <ButtonGroup role="tablist">
                          <Button
                            aria-selected={eventTab === 0}
                            className={`btn-tab mb-sm-3 mb-md-0 ${
                              eventTab === 0 && "active"
                            }`}
                            outline
                            color="dark"
                            size="md"
                            onClick={() => toggleEventNavs(0)}
                            type="button"
                            role="tab"
                          >
                            <FontAwesomeIcon
                              icon="fa-list-check"
                              className="mr-1"
                            />
                            Joined
                          </Button>
                          <Button
                            aria-selected={eventTab === 1}
                            className={`btn-tab mb-sm-3 mb-md-0 ${
                              eventTab === 1 && "active"
                            }`}
                            outline
                            color="dark"
                            size="md"
                            onClick={() => toggleEventNavs(1)}
                            type="button"
                            role="tab"
                          >
                            <FontAwesomeIcon
                              icon="fa-square-plus"
                              className="mr-1"
                            />
                            Created
                          </Button>
                        </ButtonGroup>
                      </div>
                      <TabContent activeTab={"eventTab" + eventTab}>
                        <TabPane tabId="eventTab0">
                          <Row className="mt-3">
                            {/* <Col className='text-left my-lg-3 col-6'>
                              <h5 className='text-default'>
                                <strong>Event Access</strong>
                              </h5>
                            </Col> */}

                            {/* <Col className="text-right my-lg-3">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  caret
                                  outline
                                  size="md"
                                  type="button"
                                  className="filter-button"
                                >
                                  <span className="mx-2">
                                    {showAccessOption}
                                  </span>
                                </DropdownToggle>
                                <DropdownMenu
                                  className="full-dropdown-menu-right-md filter-button-menu"
                                  style={{ margin: 0 }}
                                >
                                  <DropdownItem
                                    onClick={() => {
                                      if (showAccessOption !== "active") {
                                        setJoinedEvents([]);
                                        setMoreJoinedEvents(true);
                                        setJoinedEventsPageIndex(0);
                                      }
                                      setShowAccessOption("active");
                                    }}
                                  >
                                    <span className="">Show Active</span>
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      if (showAccessOption !== "all") {
                                        setJoinedEvents([]);
                                        setMoreJoinedEvents(true);
                                        setJoinedEventsPageIndex(0);
                                      }
                                      setShowAccessOption("all");
                                    }}
                                  >
                                    <span className="">Show All</span>
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </Col> */}
                          </Row>

                          {(!joinedEvents || joinedEvents.length <= 0) &&
                          !itemsLoading ? (
                            <>{NoDataText("lg", true)}</>
                          ) : joinedEvents?.length > 0 ? (
                            <InfiniteScroll
                              dataLength={joinedEvents.length}
                              next={() => {
                                if (eventTab === 0) {
                                  setJoinedEventsPageIndex((page) => page + 1);
                                }
                              }}
                              hasMore={moreJoinedEvents}
                            >
                              <Row className="mt-3 mb-5">
                                {showJoinedEvents()}
                              </Row>
                              <LoaderDots loading={itemsLoading} />
                            </InfiniteScroll>
                          ) : (
                            <LoaderDots loading={itemsLoading} />
                          )}
                        </TabPane>
                        <TabPane tabId="eventTab1">
                          {(!createdEvents || createdEvents.length <= 0) &&
                          !itemsLoading ? (
                            <div className="mt-5">{NoDataText("lg", true)}</div>
                          ) : createdEvents?.length > 0 ? (
                            <InfiniteScroll
                              dataLength={createdEvents.length}
                              next={() => {
                                if (eventTab === 1) {
                                  setCreatedEventsPageIndex((page) => page + 1);
                                }
                              }}
                              hasMore={moreCreatedEvents}
                            >
                              <Row className="mt-3 mb-5">
                                {showCreatedEvents()}
                              </Row>
                              <LoaderDots loading={itemsLoading} />
                            </InfiniteScroll>
                          ) : (
                            <LoaderDots loading={itemsLoading} />
                          )}
                        </TabPane>
                      </TabContent>
                    </div>
                  </TabPanel>
                  {/* <TabPanel>
                    <div className='pt-2 pb-4 px-1'>
                      <div className='text-left'>
                        <h4 className='display-4 text-default'>
                          Passes You Own
                        </h4>
                      </div>
                      {ownedPasses.length <= 0 && !itemsLoading ? (
                        <>
                          <h4 className='mt-5 mb-5 text-muted'>Coming soon.</h4>
                        </>
                      ) : (
                        <Row className='mt-1 mb-5'>{listOwnedPasses()}</Row>
                      )}
                    </div>
                  </TabPanel> */}

                  {/* <TabPanel>
                    <div className='pt-2 pb-4 px-1'>
                      <div className='text-left'>
                        <h4 className='display-4 text-default'>
                          Raffles You've Joined
                        </h4>
                      </div>
                      {joinedRaffles.length <= 0 && !itemsLoading ? (
                        <h4 className='mt-5 mb-5 text-muted'>Coming soon.</h4>
                      ) : (
                        <Row className='mt-1 mb-5'>{listJoinedRaffles()}</Row>
                      )}
                    </div>
                  </TabPanel> */}
                </Tabs>
              </div>
            </CardBody>
          </Card>
        </Container>
      </section>
    </>
  );
}

export default Account;
