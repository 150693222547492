import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "reactstrap";

import Compressor from "compressorjs";

import pluralize from "pluralize";

import { RandomHash } from "random-hash";
import { randomBytes } from "crypto";

import { ethers } from "ethers";

import Avatar from "boring-avatars";

import { Store } from "react-notifications-component";

export const truncateAddressShort = (address) => {
  if (!address) return "Unnamed";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const truncateAddressLong = (address) => {
  if (!address) return "Unnamed";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{7})[a-zA-Z0-9]+([a-zA-Z0-9]{7})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const truncateAddressXLong = (address) => {
  if (!address) return "Unnamed";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{10})[a-zA-Z0-9]+([a-zA-Z0-9]{10})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const truncateAddress2XLong = (address) => {
  if (!address) return "Unnamed";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{13})[a-zA-Z0-9]+([a-zA-Z0-9]{13})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const truncateText = (name, size) => {
  if (!name) return "Unnamed";
  if (typeof name !== "string") return name;
  const len = name.length;

  return len > size ? `${name.substring(0, size)}...` : name;
};

export const notificationHelper = (msg, type) => {
  if (type === "error") {
    return (
      <div className="text-white p-3 bg-primary w-100 rounded shadow">
        <FontAwesomeIcon icon="fa-circle-xmark" className="text-danger mr-2" />
        {msg}
      </div>
    );
  } else if (type === "success") {
    return (
      <div className="text-white p-3 bg-primary w-100 rounded shadow">
        <FontAwesomeIcon icon="fa-circle-check" className="text-success mr-2" />
        {msg}
      </div>
    );
  } else {
    return (
      <div className="text-white p-3 bg-primary w-100 rounded shadow">
        <FontAwesomeIcon
          icon="fa-circle-exclamation"
          className="text-white mr-2"
        />
        {msg}
      </div>
    );
  }
};

export const notify = (msg, type, ms) => {
  Store.addNotification({
    content: notificationHelper(msg, type),
    insert: "top",
    container: "bottom-right",
    animationIn: ["animated", "fadeInUp"],
    animationOut: ["animated", "fadeOutDown"],
    dismiss: {
      duration: ms || 3000,
    },
  });
};

export const NoDataText = (size, hasIcon, icon, title, message) => {
  return (
    <div className="text-center my-2">
      {hasIcon && (
        <div className={size === "lg" ? "display-1" : "display-3"}>
          {icon || "🙈"}
        </div>
      )}
      <div className={`mb-0 ${size === "lg" ? "h5" : "h6"}`}>
        {title || "No data available"}
      </div>
      {size === "lg" ? (
        <span className="text-muted">
          {message || "Check back for updates"}
        </span>
      ) : (
        <small className="text-muted">
          {message || "Check back for updates"}
        </small>
      )}
    </div>
  );
};

export const nativeCurrency = (chain) => {
  if (chain === "eth") return "Ξ";

  if (chain === "blast") return "BLAST";

  if (chain === "matic") return "MATIC";

  if (chain === "bsc") return "BNB";

  if (chain === "sol") return "SOL";
};

// Usage: eg: display followers
export const formatPrettyNumber = (n) => {
  if (isNaN(n)) return 0;
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export const formatCurrency = (n, symbol) => {
  const currency = Intl.NumberFormat("en-US");

  return currency.format(n) + " " + symbol?.toUpperCase();
};

// Return hex number starts with 0x
export const toHex = (num) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};

export const capitalize = (str) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
};

export const truncateWeb3Name = (name) => {
  if (!name) return "Unnamed";

  if (name?.length > 13) {
    return `${name?.substring(0, 5)}...${name?.substring(name.length - 5)}`;
  }

  return name;
};

export const truncateSimpleUrl = (url, size) => {
  if (!url) return "";

  if (url?.length > size) {
    return `${url?.substring(0, Math.floor(size / 2))}...${url?.substring(
      url?.length - Math.floor(size / 2)
    )}`;
  }

  return url;
};

// Return random hash code from seed
export const hashCode = function (seed) {
  var hash = 0,
    i,
    chr;
  if (seed?.length === 0) return hash;
  for (i = 0; i < seed?.length; i++) {
    chr = seed.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  hash = String(hash)?.replace("-", "");

  if (hash?.length > 7) {
    hash = hash?.substring(0, 7);
  }

  return hash;
};

export const genUrlSlug = (name) => {
  const generateHash = new RandomHash({
    length: 7,
    charset: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_",
  });
  const newhash = generateHash();

  let url;

  if (name) {
    url = `${name.trim().replace(/ /g, "-")}-${newhash}`.toLowerCase();
  } else {
    url = null;
  }

  return url;
};

export const hasDuplicates = (array) => {
  return new Set(array).size !== array.length;
};

export const replaceFromEnd = (str, sub) => {
  return str?.slice(0, -sub?.length) + sub;
};

export const validEthAddress = (address) => {
  const lowerAddress = address?.toLowerCase();

  const isAddress = ethers.utils.isAddress(lowerAddress);

  return isAddress;
};

export const checksumEthAddress = (address) => {
  const lowerAddress = address?.toLowerCase();

  const checksumAddress = ethers.utils.getAddress(lowerAddress);

  return checksumAddress;
};

export const validEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validUrlSlug = (url) => {
  // Only lowercase letters, numbers, or -
  const re = /^[0-9a-z\-]+$/;
  return re.test(url);
};

export const removeProtocolStr = (url) => {
  // remove the first occurence of protocol str
  return url?.replace(/(^\w+:|^)\/\//, "");
};

export const genImageName = (userId, prefix, name, genHash) => {
  let newhash = "";

  if (genHash) {
    const generateHash = new RandomHash({
      length: 7,
      charset:
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_",
    });

    newhash = generateHash() + "-";
  }

  return `user/${userId}/${prefix}-${newhash}${name}`;
};

export const DayMS = 24 * 60 * 60 * 1000;

export const nDaysAfter = (n) => new Date(new Date().setHours(24 * n, 0, 0, 0));
export const nDaysBefore = (n) =>
  new Date(new Date().setHours(-24 * n, 0, 0, 0));

// Display date with time zone
export const DateFormatWithTZ = (date, tz) => {
  return date.toLocaleString("en-US", { timeZone: tz || "UTC" });
};

// Display date with time zone
export const DateWithTZ = (date, tz) => {
  return new Date(date.toLocaleString("en-US", { timeZone: tz || "UTC" }));
};

export const nDaysAfterTZ = (n, tz) =>
  new Date(DateWithTZ(new Date(), tz).setHours(24 * n, 0, 0, 0));

export const nDaysBeforeTZ = (n, tz) =>
  new Date(DateWithTZ(new Date(), tz).setHours(-24 * n, 0, 0, 0));

export const nMSAfter = (n) => {
  if (n < 0) return;
  if (n === 0) return new Date();

  return new Date(new Date().getTime() + n);
};

export const nMSBefore = (n) => {
  if (n < 0) return;
  if (n === 0) return new Date();

  return new Date(new Date().getTime() - n);
};

/*
 * Return time between two dates
 * Start, end in ms
 */
export const getTimeBetween = (start, end) => {
  const diff = end - start;
  const days = Math.floor(diff / DayMS);
  const hours = Math.floor((diff % DayMS) / (60 * 60 * 1000));
  const minutes = Math.round(((diff % DayMS) % (60 * 60 * 1000)) / (60 * 1000));
  return { days, hours, minutes };
};

// Return days, hours, or minutes between two dates
export const getFormattedTimeBetween = (start, end) => {
  const { days, hours, minutes } = getTimeBetween(start, end);

  if (days >= 1) {
    return pluralize("day", days || 0, true);
  } else if (hours >= 1) {
    return pluralize("hour", hours || 0, true);
  } else {
    return pluralize("minute", minutes || 0, true);
  }
};

// Util for react datetime range
export const handleReactDatetimeChange = (which, date, start, end) => {
  const duration = {};
  if (which === "startDate") {
    duration.start = date;
  }

  if (which === "endDate") {
    duration.end = date;
  }

  return duration;
};

// Util for react datetime range
export const getClassNameReactDatetimeDays = (date, start, end) => {
  if (date && end && start?._d + "" !== end?._d + "") {
    if (
      new Date(end?._d + "") > new Date(date?._d + "") &&
      new Date(start?._d + "") < new Date(date?._d + "")
    ) {
      return " middle-date";
    }
    if (end?._d + "" === date?._d + "") {
      return " end-date";
    }
    if (start?._d + "" === date?._d + "") {
      return " start-date";
    }
  }
  return "";
};
