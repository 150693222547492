import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

// reactstrap components
import {
  Button,
  Collapse,
  UncontrolledCollapse,
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";

import OutsideClickHandler from "react-outside-click-handler";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";

import Autosuggest from "react-autosuggest";

import { ReactSession } from "react-client-session";

import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
  useNetwork,
  useSignMessage,
} from "wagmi";

import Avatar from "boring-avatars";

import {
  truncateAddressShort,
  truncateWeb3Name,
  truncateText,
} from "utils/Utils.js";

import { ethers } from "ethers";

import FirebaseInit from "utils/FirebaseInit.js";

import { AuthContext } from "Auth.js";

const { REACT_APP_API_HOST } = process.env;

const logoImg = require("assets/img/logos/logo.svg");

export default function TopNavbar() {
  const {
    firebaseDb,
    firebaseAuth,
    signInWithCustomToken,
    onAuthStateChanged,
    signOut,
  } = FirebaseInit();

  const { currentUser, currentUserInfo } = useContext(AuthContext);

  const { address, connector, isConnected } = useAccount();

  const { data: ensAvatar } = useEnsAvatar({
    address: currentUserInfo?.ethAddress,
  });
  const { data: ensName } = useEnsName({
    address: currentUserInfo?.ethAddress,
  });

  const navigate = useNavigate();

  const location = useLocation();
  const path = location.pathname;

  const [collapseClasses, setCollapseClasses] = useState("");
  const [collapseNavOpen, setCollapseNavOpen] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // const [hasNotification, setHasNotification] = useState(false);

  // const [searchOpen, setSearchOpen] = useState(false);
  // const [searchLoading, setSearchLoading] = useState("");
  // const [searchValue, setSearchValue] = useState("");
  // const [searchSuggestions, setSearchSuggestions] = useState([]);
  // const [searchResults, setSearchResults] = useState([
  //   {
  //     name: "Metaverse Fest",
  //     creator: "BigL",
  //   },
  //   {
  //     name: "Bored Ape Party",
  //     creator: "Will",
  //   },
  // ]);

  const onExiting = () => {
    setCollapseClasses("collapsing-out collapsing-nav");
  };

  const onExited = () => {
    setCollapseClasses("collapsing-in collapsing-nav");
  };

  const logOut = async () => {
    try {
      await signOut(firebaseAuth);

      ReactSession.remove("newEmail");

      navigate("/signin", { state: { prevPath: location.pathname } });
    } catch (err) {
      console.error(err.message);
    }
  };

  // TODO:
  // const renderSearchInput = (inputProps) => (
  //   <InputGroup className='input-search-wrapper input-group-plain input-group-merge'>
  //     <InputGroupText className='input-search-left'>
  //       <FontAwesomeIcon icon='fa-magnifying-glass' />
  //     </InputGroupText>
  //     <Input
  //       {...inputProps}
  //       className='input-search-mid'
  //       placeholder='Search'
  //     />

  //     <InputGroupText className='input-search-right'>
  //       {searchLoading && (
  //         <ScaleLoader
  //           color='#eee'
  //           loading={searchLoading}
  //           height='10px'
  //           cssOverride={{
  //             display: "inline",
  //           }}
  //         />
  //       )}
  //       {searchValue && !searchLoading && (
  //         <button
  //           aria-label='Close'
  //           className='close'
  //           type='button'
  //           onClick={() => {
  //             setSearchValue("");
  //             setSearchSuggestions([]);
  //           }}
  //         >
  //           <span aria-hidden={true}>&times;</span>
  //         </button>
  //       )}
  //     </InputGroupText>
  //   </InputGroup>
  // );

  // TODO:
  // Teach Autosuggest how to calculate suggestions for any given input value.
  // const getSuggestions = (value) => {
  //   const inputValue = value?.trim().toLowerCase();
  //   const inputLength = inputValue.length;

  //   return inputLength === 0
  //     ? []
  //     : searchResults.filter(
  //         (result) =>
  //           result?.name?.toLowerCase().slice(0, inputLength) === inputValue
  //       );
  // };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion.
  const getSuggestionValue = (suggestion) => suggestion?.name;

  // TODO: Search suggestion scollable and max result
  // const renderSuggestion = (suggestion) => (
  //   <div className='text-left'>{suggestion?.name}</div>
  // );

  // const onSearchChange = (event, { newValue }) => {
  //   // setSearchLoading(true);
  //   setSearchValue(newValue);
  // };

  // Autosuggest will pass through all these props to the input.
  // const searchProps = {
  //   placeholder: "Search",
  //   value: searchValue,
  //   onChange: onSearchChange,
  // };

  // const onSuggestionsFetchRequested = ({ value }) => {
  //   setSearchSuggestions(getSuggestions(value));
  // };

  // Autosuggest will call this function every time you need to clear suggestions.
  // const onSuggestionsClearRequested = () => {
  //   setSearchSuggestions([]);
  // };

  useEffect(() => {}, []);

  return (
    <>
      <header className="header-global">
        <Navbar
          className="navbar-main shadow"
          expand="lg"
          id="navbarMain"
          fixed="top"
          style={{ zIndex: "1001" }}
        >
          <Container className="navbar-container">
            <Row>
              <Col>
                <NavbarBrand className="mr-lg-5" href="/">
                  <img
                    className="logo mt-2"
                    alt="..."
                    src={require("assets/img/logos/logo.svg")}
                  />
                </NavbarBrand>
              </Col>
              {/* <Col className='d-none d-sm-none d-md-inline d-lg-inline d-xl-inline'>
                <div className='search-box-wrapper ml-1 mt-1 mb-0'>
                  <Autosuggest
                    suggestions={searchSuggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    renderInputComponent={(inputProps) =>
                      renderSearchInput(inputProps)
                    }
                    inputProps={searchProps}
                  />
                </div>
              </Col> */}
              <Col>
                <div className="text-right">
                  {/* <Button
                    color='link'
                    className='navbar-toggler btn-no-style text-primary mt-2 mx-0 d-md-none d-lg-none d-xl-none'
                    onClick={() => setSearchOpen(!searchOpen)}
                  >
                    <FontAwesomeIcon
                      icon='fa-magnifying-glass'
                      className='fa-lg'
                    />
                  </Button> */}
                  {/* <Button
                    id='notification'
                    color='link'
                    className='navbar-toggler btn-no-style text-primary mt-2 mx-0 d-md-none d-lg-none d-xl-none'
                  >
                    <FontAwesomeIcon icon='fa-bell' className='fa-lg' />
                  </Button> */}
                  <Button
                    color="link"
                    className="navbar-toggler text-primary mt-2"
                    id="navbarGlobal"
                    onClick={() => setCollapseNavOpen(true)}
                  >
                    <FontAwesomeIcon
                      icon="fa-bars text-primary"
                      className="fa-lg"
                    />
                  </Button>
                </div>
                <OutsideClickHandler
                  onOutsideClick={() => setCollapseNavOpen(false)}
                >
                  <Collapse
                    isOpen={collapseNavOpen}
                    toggler="#navbarGlobal"
                    navbar
                    className={collapseClasses}
                    onExiting={onExiting}
                    onExited={onExited}
                  >
                    <div className="navbar-collapse-header container-fluid">
                      <Row>
                        <Col className="collapse-brand" xs="6">
                          <a href="/">
                            <img className="logo" alt="..." src={logoImg} />
                          </a>
                        </Col>
                        <Col className="collapse-close">
                          <Button
                            color="link"
                            className="btn-icon-only text-primary mt-0"
                            type="button"
                            id="navbarGlobal"
                            onClick={() => setCollapseNavOpen(false)}
                          >
                            <FontAwesomeIcon
                              icon="fa-xmark text-primary"
                              className="fa-xl"
                            />
                          </Button>
                        </Col>
                      </Row>
                    </div>

                    <Nav className="align-items-lg-center" navbar>
                      <NavItem></NavItem>
                    </Nav>
                    <Nav className="align-items-lg-center ml-lg-auto" navbar>
                      {/* 
                      <NavItem>
                        <NavLink className='nav-link-icon'>
                          <FontAwesomeIcon
                            icon='fa-passport'
                            className='nav-link-inner--text d-lg-none'
                          />
                          <span className={`ml-3 text-nav px-2 pb-2`}>DID</span>
                        </NavLink>
                      </NavItem> */}

                      <NavItem>
                        {/* <NavLink className='nav-link-icon' href='/explore'>
                          <FontAwesomeIcon
                            icon='fa-compass'
                            className='nav-link-inner--text d-lg-none'
                          />
                          <span
                            className={`ml-3 text-nav px-2 pb-2 ${
                              path === "/explore" ? "current-nav" : ""
                            }`}
                          >
                            Explore
                          </span>
                        </NavLink> */}
                      </NavItem>

                      <NavItem>
                        <NavLink className="nav-link-icon" href="/create/event">
                          <FontAwesomeIcon
                            icon="fa-rocket"
                            className="nav-link-inner--text d-lg-none"
                          />
                          <span
                            className={`ml-3 text-nav px-2 pb-2 ${
                              path.includes("/create/event")
                                ? "current-nav"
                                : ""
                            }`}
                          >
                            Launch 🔥
                          </span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className="nav-link-icon"
                          href="https://twitter.com/realpassxyz"
                        >
                          <FontAwesomeIcon
                            icon="fa-brands fa-twitter"
                            className="nav-link-inner--text"
                          />
                          <span className="text-nav ml-3 px-2 pb-2 d-lg-none d-xl-none d-md-block d-sm-block d-xs-block">
                            Follow us
                          </span>
                        </NavLink>
                      </NavItem>

                      {/* <NavItem>
                        <NavLink
                          className="nav-link-icon"
                          href="https://discord.gg/realpassxyz"
                        >
                          <FontAwesomeIcon
                            icon="fa-brands fa-discord"
                            className="nav-link-inner--text"
                          />
                          <span className="text-nav ml-3 px-2 pb-2 d-lg-none d-xl-none d-md-block d-sm-block d-xs-block">
                            Join Discord
                          </span>
                        </NavLink>
                      </NavItem> */}

                      {/* <NavItem className='d-none d-lg-block d-xl-block d-md-block d-sm-none d-xs-none'>
                        <NavLink id='notification'>
                          <FontAwesomeIcon
                            icon='fa-bell'
                            className={`${
                              hasNotification ? "text-info" : "text-primary"
                            }`}
                            style={{ cursor: "pointer" }}
                          />
                        </NavLink>
                      </NavItem> */}
                      {currentUser ? (
                        <>
                          <Dropdown
                            isOpen={isDropdownOpen}
                            toggle={() => setIsDropdownOpen(!isDropdownOpen)}
                            onMouseOver={() => setIsDropdownOpen(true)}
                            onMouseLeave={() => setIsDropdownOpen(false)}
                            nav
                          >
                            <DropdownToggle
                              color="link"
                              className="collapse-dropdown-toggle ml-3"
                            >
                              <span className="rounded-circle">
                                {currentUserInfo?.imageUrl ? (
                                  <img
                                    src={currentUserInfo?.imageUrl}
                                    alt="Profile Avatar"
                                    className="profile-image-xs rounded-circle float-left"
                                  />
                                ) : ensAvatar ? (
                                  <img
                                    src={ensAvatar}
                                    alt="ENS Avatar"
                                    className="profile-image-xs rounded-circle float-left"
                                  />
                                ) : (
                                  <Avatar
                                    size={30}
                                    name={currentUserInfo?.ethAddress}
                                    square="false"
                                    variant="ring"
                                    colors={[
                                      "#000000",
                                      "#2F2F2F",
                                      "#505050",
                                      "#797979",
                                      "#CECECE",
                                    ]}
                                  />
                                )}
                              </span>
                            </DropdownToggle>
                            <DropdownMenu className="collapse-dropdown-item">
                              <div className="dropdown-item d-none d-lg-block">
                                <span className="rounded-circle">
                                  {currentUserInfo?.imageUrl ? (
                                    <img
                                      src={currentUserInfo?.imageUrl}
                                      alt="Profile Avatar"
                                      className="profile-image-xs rounded-circle float-left"
                                    />
                                  ) : ensAvatar ? (
                                    <img
                                      src={ensAvatar}
                                      alt="ENS Avatar"
                                      className="profile-image-xs rounded-circle float-left"
                                    />
                                  ) : (
                                    <Avatar
                                      size={30}
                                      name={currentUserInfo?.ethAddress}
                                      square="false"
                                      variant="ring"
                                      colors={[
                                        "#000000",
                                        "#2F2F2F",
                                        "#505050",
                                        "#797979",
                                        "#CECECE",
                                      ]}
                                    />
                                  )}
                                </span>
                                <span className="ml-2">
                                  {truncateText(currentUserInfo?.name, 15) ||
                                    ensName ||
                                    truncateAddressShort(
                                      currentUserInfo?.ethAddress
                                    )}
                                </span>
                              </div>
                              <DropdownItem divider />
                              <DropdownItem href="/account">
                                <FontAwesomeIcon icon="fa-circle-user" />
                                <span
                                  className={`text-nav px-2 pb-2 ${
                                    path === "/account" ? "current-nav" : ""
                                  }`}
                                >
                                  Account
                                </span>
                              </DropdownItem>
                              <DropdownItem href="/settings">
                                <FontAwesomeIcon icon="fa-cog" />
                                <span
                                  className={`text-nav px-2 pb-2 ${
                                    path === "/settings" ? "current-nav" : ""
                                  }`}
                                >
                                  Settings
                                </span>
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem onClick={() => logOut()}>
                                <FontAwesomeIcon icon="fa-arrow-right-from-bracket" />
                                <span className="text-nav">Logout</span>
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </>
                      ) : (
                        <>
                          <NavItem className="mx-2">
                            <Link
                              to="/signin"
                              state={{ prevPath: location.pathname }}
                            >
                              <Button
                                block
                                outline
                                color="dark"
                                type="button"
                                style={{
                                  whiteSpace: "nowrap",
                                  textAlign: "center",
                                }}
                              >
                                <FontAwesomeIcon icon="fa-globe" />
                                <span>Signin</span>
                              </Button>
                            </Link>
                          </NavItem>
                        </>
                      )}
                    </Nav>
                  </Collapse>
                </OutsideClickHandler>
              </Col>
              {/* {searchOpen && (
                <Col className='col-12 d-md-none d-lg-none d-xl-none'>
                  <div className='search-box-wrapper ml-1 mt-3 d-md-none d-lg-none d-xl-none'>
                    <Autosuggest
                      id='navbarSearch'
                      suggestions={searchSuggestions}
                      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                      onSuggestionsClearRequested={onSuggestionsClearRequested}
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={renderSuggestion}
                      renderInputComponent={(inputProps) =>
                        renderSearchInput(inputProps)
                      }
                      inputProps={searchProps}
                    />
                  </div>
                </Col>
              )} */}
            </Row>
            {/* <UncontrolledPopover
              trigger='hover'
              placement='bottom'
              target='notification'
            >
              <PopoverBody className='p-4 d-flex'>
                <Row>
                  <Col>
                    {!hasNotification && (
                      <span className='text-muted'>No new notifications.</span>
                    )}
                  </Col>
                </Row>
              </PopoverBody>
            </UncontrolledPopover> */}
          </Container>
        </Navbar>
      </header>
    </>
  );
}
