import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledCarousel } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IsometricGrid, { Cell } from "react-isometric-grid";
import dynamics from "dynamics.js";

const flyingText =
  " REALPASS ★ LBT ★ CRM ★ EVENTS ★ MEMBERSHIP ★ NFT ★ LOYALTY ★ REWARDS ★ COMMUNITIES ★ BRANDS ★ MERCH ★ GAMING ★ MUSIC ★ METAVERSE ★ DAO ★ ".repeat(
    2
  );

const nft01 = require("assets/img/nfts/01.svg");
const nft02 = require("assets/img/nfts/02.svg");
const nft03 = require("assets/img/nfts/03.svg");
const nft04 = require("assets/img/nfts/04.svg");
const nft05 = require("assets/img/nfts/05.svg");
const nft06 = require("assets/img/nfts/06.svg");
const nft07 = require("assets/img/nfts/07.svg");
const nft08 = require("assets/img/nfts/08.svg");
const nft09 = require("assets/img/nfts/09.svg");
const bgImg = require("assets/img/bg/abstract-line-03.svg");

export default function HeroIsoGrids() {
  const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  return (
    <>
      <div className="d-none d-xl-block d-lg-block d-md-block bg-transparent">
        <img
          alt="..."
          className="bg-image"
          src={bgImg}
          style={{ zIndex: -1 }}
        />
      </div>
      <section className="section section-shaped pt-0">
        <Container className="pt-0">
          <Row className="justify-content-between align-items-center">
            <Col className="mt-5 mb-lg-0" lg="5">
              <h2 className="display-2">
                Bringing NFTs to life, one location at a time
              </h2>
              <p className="lead mt-4">
                Empowering brands and NFT communities with the power of
                Location-based Tokens (LBTs)
              </p>

              <div className="btn-wrapper mt-5">
                <Button
                  className="btn-icon mb-3 mb-sm-0 ml-2 mr-2"
                  color="dark"
                  href="/create/event"
                  size="lg"
                >
                  <i className="ni ni-spaceship mr-2" />
                  Get Early Access
                </Button>
                <Button
                  className="btn-icon mb-3 mb-sm-0 ml-2 mr-2"
                  outline
                  color="dark"
                  href="https://forms.gle/xLrNXeQHnZZyWwT5A"
                  target="_blank"
                  rel="external nofollow noopener"
                  size="lg"
                >
                  <FontAwesomeIcon icon="fa-star" className="mr-2" />
                  Brand Access
                </Button>
              </div>
            </Col>
            <Col className="mb-lg-auto" lg="5">
              <IsometricGrid
                shadow
                transform="rotateX(45deg) rotateZ(45deg)"
                stackItemsAnimation={{
                  properties: function (pos) {
                    return {
                      translateZ: (pos + 1) * 30,
                      rotateZ: getRandomInt(-4, 4),
                    };
                  },
                  options: function (pos, itemstotal) {
                    return {
                      type: dynamics.bezier,
                      points: [
                        { x: 0, y: 0, cp: [{ x: 0.2, y: 1 }] },
                        { x: 1, y: 1, cp: [{ x: 0.3, y: 1 }] },
                      ],
                    };
                  },
                }}
                style={{ height: "600px", width: "600px" }}
              >
                <Cell layers={[nft01]} />
                <Cell layers={[nft02]} />
                <Cell layers={[nft03]} />
                <Cell layers={[nft04]} />
                <Cell layers={[nft05]} />
                <Cell layers={[nft06]} />
                <Cell layers={[nft07]} />
                <Cell layers={[nft08]} />
                <Cell layers={[nft09]} />
              </IsometricGrid>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="marquee-wrapper">
        <div className="marquee marquee-left">
          <p className="m-0 text-dark">{flyingText}</p>
        </div>
      </div>
    </>
  );
}
